/**
 * Ensure that the game the user wants to start in demo mode is available in demo mode.
 *
 * Checks if the game the user is trying to access in demo mode (based on the gamePrettyName from the route)
 * supports demo mode. It does this by fetching the game data using the fetchGameByPrettyNameForSSR.
 * After fetching the game data it evaluates whether the game supports demo mode by checking the demoModeSupport
 * flag from the store. If demo mode is not supported the user is redirected to the homepage
 */
import { storeToRefs } from 'pinia';
import { useGamesStore } from '~/stores/games';
import { GAME_MODES } from '~/constants/game';
import { PLATFORM } from '~/constants/general';

export default defineNuxtRouteMiddleware(async (to) => {
  const { fetchGameByPrettyNameForSSR } = useGamesStore();
  const { getGameData } = storeToRefs(useGamesStore());
  const { isMobileOrTablet } = useDevice();
  const currentPlatform = isMobileOrTablet
    ? PLATFORM.mobile
    : PLATFORM.desktop;
  const fetchGameParams = {
    prettyName: to.params.gamePrettyName as string,
    platform: currentPlatform,
  };

  await fetchGameByPrettyNameForSSR(fetchGameParams);
  if (to.params.mode === GAME_MODES.fun && !getGameData.value.demoModeSupport) {
    return navigateTo({ path: useLocalePrefixForHref('/') });
  }
});
